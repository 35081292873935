import { __decorate } from "tslib";
// Watch
import { Component, Vue } from 'vue-property-decorator';
import ImprimirTabelaComponent from '@/components/ImprimirTabelaComponent.vue';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
import GenericListTotalCardComponent from '@/components/GenericListTotalCardComponent.vue';
import GenericListItemsCardComponent from '@/components/GenericListItemsCardComponent.vue';
import AulaLocalShowButton from '@/components/AulaLocalShowButton.vue';
import VueTimepicker from '@/components/vue-timepicker.vue';
import { analyticsStore, cursoStore, livroStore, mainStore, salaStore, turmaStore, userStore, userToolStore, } from '@/store';
import _ from 'lodash';
let TurmaList = class TurmaList extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            {
                show: true,
                table: 'turma',
                text: 'Referência',
                type: '',
                value: 'codigo_referencia',
            },
            {
                show: true,
                table: 'livro',
                text: 'Livro',
                type: '',
                value: 'livro',
                field: 'nome',
            },
            {
                show: true,
                table: 'livro',
                text: 'Edição',
                type: '',
                value: 'livro_edicao',
                field: 'edicao',
            },
            {
                show: true,
                table: 'turma',
                text: 'Modalidade',
                type: '',
                value: 'modalidade_id',
            },
            {
                show: true,
                table: 'sala',
                text: 'Sala',
                type: '',
                value: 'sala_nome',
                field: 'nome',
            },
            {
                show: true,
                text: 'Alunos/Vagas',
                type: '',
                value: 'numero_aluno',
                table: 'turma',
            },
            {
                show: true,
                table: 'turma',
                text: 'Professor',
                type: '',
                value: 'professor_id',
            },
            {
                show: true,
                table: 'turma',
                text: 'Início da turma',
                type: '',
                value: 'previsao_inicio',
            },
            {
                show: true,
                table: 'turma',
                text: 'Previsão de fim',
                type: '',
                value: 'previsao_termino',
            },
            {
                show: true,
                table: 'turma',
                text: 'Horários',
                type: '',
                value: 'cronograma_horario',
            },
            {
                show: true,
                text: 'Status da turma',
                type: '',
                table: 'turma',
                value: 'status_id',
            },
            {
                show: true,
                text: 'Qtd. de conteúdos ',
                type: '',
                value: 'atividade_quantidade',
                table: 'turma',
            },
            {
                show: true,
                text: 'Último conteúdo',
                type: '',
                value: 'ultima_atividade_descricao',
                table: 'turma',
            },
            {
                show: true,
                text: 'Presenças na ficha de frequência',
                type: '',
                value: 'ficha_frequencia_atualizada',
                table: 'turma',
            },
            {
                show: true,
                text: 'Local aula',
                type: '',
                value: 'local_aula_id',
                table: 'turma',
            },
        ];
        this.filtroPorStatusSelected = [
            { text: 'Em formação', id: 1 },
            { text: 'Vigente', id: 2 },
        ];
        this.filtroCodigoReferencia = '';
        this.filtroHoraInicio = { HH: '', mm: '' };
        this.filtroHoraFim = { HH: '', mm: '' };
        this.filtroLivroSelected = null;
        this.filtroSalaSelected = null;
        this.filtroCursoSelected = null;
        this.filtroProfessorSelected = null;
        this.diaDaSemanaSelected = [];
        this.timer = 0;
        this.loading = false;
        this.selectedItem = {};
        this.professorOptions = [];
        this.turmas = [];
        this.tabelaRef = null;
        this.search = '';
        this.carregandoTotals = false;
    }
    get livroOptions() {
        return livroStore.livros;
    }
    get salaOptions() {
        return salaStore.salas;
    }
    get cursoOptions() {
        return cursoStore.cursos;
    }
    get totals() {
        return analyticsStore.totals;
    }
    get allowView() {
        if (userToolStore.userToolsIndexed[`Turma`]) {
            return userToolStore.userToolsIndexed[`Turma`][0].allow_view;
        }
    }
    get allowCreate() {
        if (userToolStore.userToolsIndexed[`Turma`]) {
            return userToolStore.userToolsIndexed[`Turma`][0].allow_create;
        }
    }
    get allowEdit() {
        if (userToolStore.userToolsIndexed[`Turma`]) {
            return userToolStore.userToolsIndexed[`Turma`][0].allow_edit;
        }
    }
    get allowDelete() {
        if (userToolStore.userToolsIndexed[`Turma`]) {
            return userToolStore.userToolsIndexed[`Turma`][0].allow_delete;
        }
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    customSort(items, index, isDesc) {
        items.sort((a, b) => {
            if (index[0] === 'cronograma_horario') {
                let valorA = 0;
                let valorB = 0;
                if (a.cronograma_horario.length > 0) {
                    valorA =
                        a.cronograma_horario[0].dia_semana_index * 2400 +
                            +a.cronograma_horario[0].horario.replace(':', '');
                }
                if (b.cronograma_horario.length > 0) {
                    valorB =
                        b.cronograma_horario[0].dia_semana_index * 2400 +
                            +b.cronograma_horario[0].horario.replace(':', '');
                }
                if (!isDesc[0]) {
                    return valorA < valorB ? -1 : 1;
                }
                else {
                    return valorB < valorA ? -1 : 1;
                }
            }
            else {
                if (!isDesc[0]) {
                    return a[index[0]] < b[index[0]] ? -1 : 1;
                }
                else {
                    return b[index[0]] < a[index[0]] ? -1 : 1;
                }
            }
        });
        return items;
    }
    async doSearchItems(queryIn) {
        return await turmaStore.getTurmaQuery(queryIn);
    }
    async doSearchTotals() {
        // o correto é sempre passar o default filters para o analytics total
        await analyticsStore.getAnalyticsTurmas(analyticsStore.defaultFilters);
    }
    async buscarTotals() {
        this.carregandoTotals = true;
        await this.doSearchTotals();
        this.carregandoTotals = false;
    }
    async buscar() {
        analyticsStore.initAnalytics();
        const filters = {};
        // por status
        if (this.filtroPorStatusSelected) {
            const ids = _.map(this.filtroPorStatusSelected, 'id');
            filters['turma.status_id'] = {
                type: 'int',
                value: ids,
            };
        }
        if (this.filtroHoraInicio) {
            filters['tmp.hora_inicio'] = {
                type: 'str',
                value: this.filtroHoraInicio,
            };
        }
        if (this.filtroHoraFim) {
            filters['tmp.hora_fim'] = {
                type: 'str',
                value: this.filtroHoraFim,
            };
        }
        if (this.diaDaSemanaSelected) {
            filters['tmp.dia_semana'] = {
                type: 'int',
                value: this.diaDaSemanaSelected,
            };
        }
        if (this.filtroCodigoReferencia) {
            filters['turma.codigo_referencia'] = {
                type: 'int',
                value: [this.filtroCodigoReferencia],
            };
        }
        if (this.filtroLivroSelected) {
            filters['turma.livro_id'] = {
                type: 'int',
                value: [this.filtroLivroSelected.id],
            };
        }
        if (this.filtroSalaSelected) {
            filters['turma.sala_id'] = {
                type: 'int',
                value: [this.filtroSalaSelected],
            };
        }
        if (this.filtroProfessorSelected) {
            filters['turma.professor_id'] = {
                type: 'int',
                value: [this.filtroProfessorSelected],
            };
        }
        if (this.filtroCursoSelected) {
            filters['livro.curso_id'] = {
                type: 'int',
                value: [this.filtroCursoSelected],
            };
        }
        analyticsStore.setDefaultFilters(filters);
        await analyticsStore.executeSearchItems();
    }
    async mounted() {
        await salaStore.getSalas();
        this.professorOptions = await userStore.getProfessores();
        // @ts-ignore
        this.diaDaSemanaSelected = this.diasDaSemana();
        analyticsStore.initAnalytics();
        analyticsStore.setFnSearchItems(turmaStore.getTurmaQuery);
        analyticsStore.setHeaders(this.headers);
        this.buscar();
    }
    routeEdit(id) {
        this.$router.push({ name: 'main-turmas-edit', params: { id } });
    }
};
TurmaList = __decorate([
    Component({
        components: {
            ImprimirTabelaComponent,
            OpenNewTabComponent,
            GenericListTotalCardComponent,
            GenericListItemsCardComponent,
            AulaLocalShowButton,
            VueTimepicker,
        },
    })
], TurmaList);
export default TurmaList;
